// React
import React from "react";
import Helmet from "react-helmet";

// Internal dependencies
import "./index.css";


const NotFoundPage = () =>
(
    <React.Fragment>

        <Helmet>
            <title>GoodSpot - 404</title>
        </Helmet>

        <span>Page not found!</span>
        
    </React.Fragment>
);

export default NotFoundPage;